@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .container {

    @screen sm {
      max-width: 540px;
    }

    @screen md {
      max-width: 720px;
    }

    @screen lg {
      max-width: 788px;
    }
  }

  .icon {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    vertical-align: -0.125em;
  }
}

/* progressive blur */
.gradient-blur::before {
  mask: linear-gradient(to bottom,
      rgba(9, 9, 11, 0) 0%,
      rgba(9, 9, 11, 1) 12.5%,
      rgba(9, 9, 11, 1) 25%,
      rgba(9, 9, 11, 0) 37.5%);
}

.gradient-blur>div:nth-of-type(1) {
  mask: linear-gradient(to bottom,
      rgba(9, 9, 11, 0) 12.5%,
      rgba(9, 9, 11, 1) 25%,
      rgba(9, 9, 11, 1) 37.5%,
      rgba(9, 9, 11, 0) 50%);
}

.gradient-blur>div:nth-of-type(2) {
  mask: linear-gradient(to bottom,
      rgba(9, 9, 11, 0) 25%,
      rgba(9, 9, 11, 1) 37.5%,
      rgba(9, 9, 11, 1) 50%,
      rgba(9, 9, 11, 0) 62.5%);
}

.gradient-blur>div:nth-of-type(3) {
  mask: linear-gradient(to bottom,
      rgba(9, 9, 11, 0) 37.5%,
      rgba(9, 9, 11, 1) 50%,
      rgba(9, 9, 11, 1) 62.5%,
      rgba(9, 9, 11, 0) 75%);
}

.gradient-blur>div:nth-of-type(4) {
  mask: linear-gradient(to bottom,
      rgba(9, 9, 11, 0) 50%,
      rgba(9, 9, 11, 1) 62.5%,
      rgba(9, 9, 11, 1) 75%,
      rgba(9, 9, 11, 0) 87.5%);
}

.gradient-blur>div:nth-of-type(5) {
  mask: linear-gradient(to bottom,
      rgba(9, 9, 11, 0) 62.5%,
      rgba(9, 9, 11, 1) 75%,
      rgba(9, 9, 11, 1) 87.5%,
      rgba(9, 9, 11, 0) 100%);
}

.gradient-blur>div:nth-of-type(6) {
  mask: linear-gradient(to bottom,
      rgba(9, 9, 11, 0) 75%,
      rgba(9, 9, 11, 1) 87.5%,
      rgba(9, 9, 11, 1) 100%);
}

.gradient-blur::after {
  mask: linear-gradient(to bottom,
      rgba(9, 9, 11, 0) 87.5%,
      rgba(9, 9, 11, 1) 100%);
}